import React, { useEffect } from "react"
import QueryString from "query-string"
import PageRoot from "../../components/PageRoot"
import { getImageUrl, sendPurchaseEvent, translatePath } from "../../utils"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { Link } from "gatsby"
import { Button, Card, Skeleton } from "antd"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../constants/imageSizes"

const GET_PURCHASE_QUERY = gql`
  query getPurchase($id: uuid!) {
    user_purchases_by_pk(id: $id) {
      currency
      created_at
      description
      id
      item_id
      price
      state
      item_type
      user_id
      VIDEO: video {
        id
        title
        subtitle
        short_description
        duration
        price
        price_policy
        landscape {
          s3_key
        }
      }
      COURSE: course {
        id
        title
        subtitle
        short_description
        price
        price_policy
        landscape {
          s3_key
        }
      }
      CERTIFICATION: certification {
        id
        title
        subtitle
        short_description
        price
        landscape_success {
          s3_key
        }
        landscape_locked {
          s3_key
        }
        wallpaper {
          s3_key
        }
      }
      GIFT_CARD: gift_card {
        id
        title
        subtitle
        short_description
        price
        criterion {
          picture {
            s3_key
          }
        }
      }
      BUNDLE: bundle {
        id
        title
        subtitle
        short_description
        price
        landscape {
          s3_key
        }
      }
    }
  }
`

const CheckoutCompletePage = ({ location }) => {
  const { t } = useTranslation()

  const params = QueryString.parse(location.search)
  const { id } = params
  const { loading, error, data } = useQuery(GET_PURCHASE_QUERY, {
    variables: { id },
  })

  useEffect(() => {
    sendPurchaseEvent()
  }, [])

  const { item_type, state } = data?.user_purchases_by_pk || {}

  const item = data?.user_purchases_by_pk[item_type]

  const {
    title,
    subtitle,
    short_description,
    price,
    landscape,
    landscape_locked,
    landscape_success,
    criterion,
  } = item || {}

  const coverUrl = getImageUrl(
    landscape_locked || landscape_success || landscape || criterion?.picture,
    DEFAULT_LANDSCAPE_IMAGE_SIZE
  )

  return (
    <PageRoot title={t("label:paymentSuccess")} isDark={false}>
      <ContainerWithPadding size="small" align="center">
        {loading || !item ? (
          <Skeleton active />
        ) : (
          <div className="purchase-preview-wrapper">
            <Card
              style={{ width: "100%", marginBottom: 50 }}
              hoverable
              cover={<img alt={title} src={coverUrl} />}
            >
              <Card.Meta title={title} description={short_description} />
            </Card>
          </div>
        )}
        <h2>{t("message:purchaseCompleted")}</h2>
        <p>{t("message:purchaseCompletedMessage1")}</p>

        <Button className="red" type="primary">
          <Link to={translatePath("/me/library")}>
            {t("button:goToMyLibrary")}
          </Link>
        </Button>
        <br />
        <br />
        <p>
          {t("message:or")}{" "}
          <Link className="home-link" to="/">
            {t("button:goHome")}
          </Link>
        </p>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default CheckoutCompletePage
